import React, { useEffect } from 'react';
import Slider from 'react-slick';
import '../css/AboutUsSection.css';
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import imagen1 from '../imagenes/imagenesTrabajamosEnElMedio/image24.jpg'
import imagen2 from '../imagenes/imagenesTrabajamosEnElMedio/image23.jpg'
import imagen3 from '../imagenes/imagenesTrabajamosEnElMedio/image22.jpg'
import imagen4 from '../imagenes/imagenesTrabajamosEnElMedio/image21.jpg'
import imagen5 from '../imagenes/imagenesTrabajamosEnElMedio/image20.jpg'
import imagen6 from '../imagenes/imagenesTrabajamosEnElMedio/image19.jpg'
const AboutUsSection = () => {
  // Configuración del carousel (puedes ajustar estas opciones según tus necesidades)
  const carouselSettings = {
    dots: true,
    infinite: true,
    speed: 1000, // Velocidad de desplazamiento (ms) entre las imágenes
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true, // Habilita el autoplay
    autoplaySpeed: 2000, // Velocidad del autoplay en milisegundos (ms)
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        }
      }
    ]
  };
  useEffect(() => {
    const handleScroll = () => {
      const elements = document.querySelectorAll('.hidden');
      elements.forEach((element) => {
        const positionFromTop = element.getBoundingClientRect().top;
        if (positionFromTop - window.innerHeight + 500 <= 0) {
          element.classList.add('animated');
          element.classList.remove('hidden');
        }
      });
    };
  
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <> 
    <div id='quienes-somos' className=".about-us-section hidden">
      <div className="description">
        <h2>Quiénes somos</h2>
        <ul className="about-us-list">
          <li>✓ Aislamiento térmico superior: Nuestros productos de poliuretano ofrecen un aislamiento térmico excepcional, lo que permite mantener temperaturas estables y eficientes tanto en ambientes fríos como calientes. Esto se traduce en un menor consumo energético y costos de operación reducidos para las industrias que utilizan nuestros productos.</li>

          <li>✓ Versatilidad adaptable a sus necesidades: Entendemos que cada industria tiene requisitos únicos, por lo que ofrecemos una amplia gama de formulaciones de poliuretano que se pueden adaptar a diferentes formas, tamaños y especificaciones. Nuestra capacidad de personalización nos permite brindar soluciones que satisfacen las necesidades específicas de cada cliente.</li>
          <li>✓ Soluciones adhesivas y sellantes confiables: Nuestros adhesivos y sellantes de poliuretano proporcionan una excelente adhesión y un sellado hermético en una variedad de sustratos. Esto garantiza uniones fuertes y seguras, lo que resulta en componentes y estructuras confiables y duraderos.</li>
        </ul>
      </div>
      <div className="work-gallery">
    <Slider {...carouselSettings}>

        <div className='imgCarrousel'>
          <img src={imagen1} alt="" />
        </div>
        <div className='imgCarrousel'>
        <img src={imagen2} alt="" />
        </div>
        <div className='imgCarrousel'>
        <img src={imagen3} alt="" />
        </div >
        <div className='imgCarrousel'>
        <img src={imagen4} alt="" />
        </div>
        <div className='imgCarrousel'>
          <img src={imagen5} alt="" />
        </div>
    </Slider>
  </div>
    </div>

  </>
  );
};

export default AboutUsSection;