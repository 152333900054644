import React, { Component } from "react";
import SectionImage from "../componentesHome/sectionImage";
import AboutUsSection from "../componentesHome/aboutUs";
import Footer from "./footer";
import Contacto from "../componentesHome/contacto";
import TrabajamosEnElMedio from "./trabajamosEnElMedio";

export class Home extends Component {
  render() {
    return (
      <>
       
     <SectionImage></SectionImage>
     <AboutUsSection></AboutUsSection>
    <TrabajamosEnElMedio></TrabajamosEnElMedio>
   
   
      </>
    );
  }
}


export default Home;
