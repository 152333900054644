import React from 'react';
import { FaFacebook, FaInstagram, FaTwitter } from 'react-icons/fa';
import { Link, useNavigate } from 'react-router-dom';
import { scroller } from 'react-scroll';
import '../css/mobileMenu.css';
import '../css/navbar.css'

const MobileMenu = ({ isActive, closeMenu }) => {
  const navigate = useNavigate();
  const navigateAndScroll = (route, target) => {
    navigate(route);
    setTimeout(() => {
      scroller.scrollTo(target, {
        duration: 1000,
        smooth: true,
      });
    }, 100);
  };
  return (
    <div className={`mobile-menu ${isActive ? 'active' : ''}`}>
      <div className="mobile-links-left">
      <a className="scroll-link" onClick={() => navigateAndScroll('/', 'quienes-somos')}>Quienes Somos</a>
        <Link to="/contacto">Contacto</Link>
        <a className="scroll-link" onClick={() => navigateAndScroll('/', 'trabajamos-en-el-medio-section')}>Trabajamos en el medio</a>
      </div>

      <div className="mobile-links-right">
        <a href="#" target="_blank" rel="noopener noreferrer">
          <FaFacebook />
        </a>
        <a href="#" target="_blank" rel="noopener noreferrer">
          <FaTwitter />
        </a>
        <a href="#" target="_blank" rel="noopener noreferrer">
          <FaInstagram />
        </a>
      </div>
    </div>
  );
};

export default MobileMenu;