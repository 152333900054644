import React, { useEffect, useState } from 'react';
import axios from 'axios';
import '../css/contactSection.css';



const ContactSection = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    city: '',
    message: ''
  });

  const [responseMessage, setResponseMessage] = useState('');

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    axios.post('https://back-glk3duj5l-lios-projects.vercel.app', formData)
      .then((response) => {
        setResponseMessage(response.data.message);
        setFormData({
          name: '',
          email: '',
          phone: '',
          city: '',
          message: ''
        });
      })
      .catch((error) => {
        setResponseMessage('Ha ocurrido un error al enviar el correo.');
        console.error(error);
      });
  };
  useEffect(() => {
    const handleScroll = () => {
      const elements = document.querySelectorAll('.hidden');
      elements.forEach((element) => {
        const positionFromTop = element.getBoundingClientRect().top;
        if (positionFromTop - window.innerHeight + 500 <= 0) {
          element.classList.add('animated');
          element.classList.remove('hidden');
        }
      });
    };
  
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  return (
      <>
       
       <div className="full-screen-wrapper animated">
    <div className="full-screen-form">
   
      <form className="contact-form" onSubmit={handleSubmit}>
        <div className="input-group">
          <input
            type="text"
            id="name"
            name="name"
            placeholder="Nombre"
            value={formData.name}
            onChange={handleChange}
            required
          />
        </div>
        <div className="input-group">
          <input
            className="input-half"
            type="email"
            id="email"
            name="email"
            placeholder="Email"
            value={formData.email}
            onChange={handleChange}
            required
          />
          <input
            className="input-half"
            type="phone"
            id="phone"
            name="phone"
            placeholder="Teléfono"
            value={formData.phone}
            onChange={handleChange}
            required
          />
        </div>
        <div className="input-group">
          <input
            type="text"
            id="city"
            name="city"
            placeholder="Ciudad"
            value={formData.city}
            onChange={handleChange}
            required
          />
        </div>
        <div className="input-group">
          <textarea
            id="message"
            name="message"
            placeholder="Mensaje"
            value={formData.message}
            onChange={handleChange}
            required
          />
        </div>
        <button className='boton' type="submit">Enviar</button>
      </form>
      {responseMessage && <div className="response-message">{responseMessage}</div>}
    </div>
    <div className="info-section">
        
        <div className="info-item">
          <strong>Dirección:</strong>
          <p>Av. Cabildo 359</p>
          <p>Avellaneda, Provincia de Buenos Aires</p>
        </div>
        <div className="info-item">
          <strong>E-mail:</strong>
          <p>purtecnicaaislaciones@gmail.com</p>
        </div>
        <div className="info-item">
          <strong>Celular:</strong>
          <p>+54 9 11 3209-2090
</p>
        </div>
        <div className="info-item">
          <strong>Horario de Atención:</strong>
          <p>Lunes a Viernes de 8 a 17 hs
            <br></br>
Sabado de  8 a 13 hs</p>
        </div>
        <div className="info-item">
          <strong>Formas de Pago:</strong>
          <p>Tarjetas / Efectivo / Transferencias</p>
        </div>
      </div>
    </div>
      <iframe className='full-width-iframe' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3281.058873586875!2d-58.39200292346699!3d-34.678463561450776!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x95bcccc756a6e429%3A0x9a40326067fe3045!2sPur%20Tecnica%20Aislacion%20Termica%20con%20Poliuretano%20-%20Equipos%20Termicos!5e0!3m2!1ses-419!2sar!4v1694389853807!5m2!1ses-419!2sar"></iframe>
  </>
  );
};
    


export default ContactSection;