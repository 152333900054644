import React, { PureComponent } from 'react'
import '../css/sectionImage.css'
import { Link } from 'react-router-dom'
export class sectionImage extends PureComponent {
  render() {
    return (<>
       



    <div className="hero-section">
   
      <div className="hero-content">
      <h1>Soluciones en Poliuretano</h1>
        <p>Somos una empresa con más de 30 años de trayectoria en Aislamientos Térmicos e Hidrófugos con Poliuretano Expandido. Contamos con personal calificado y los mejores materiales del mercado para brindarles soluciones eficientes y de calidad. Nuestra trayectoria y atención personalizada son nuestra diferencia.</p>
        
        <button><Link to="/contacto">Contacto</Link></button>
      </div>
    </div>

      </>
    )
  }
}

export default sectionImage