import React, { useState } from 'react';
import { Box, SimpleGrid, Image, Modal, ModalOverlay, ModalContent, ModalCloseButton, useDisclosure, useColorModeValue } from '@chakra-ui/react';
import image11 from '../imagenes/imagenesTrabajamosEnElMedio/image11.jpg';
import image12 from '../imagenes/imagenesTrabajamosEnElMedio/image12.jpg';
import image13 from '../imagenes/imagenesTrabajamosEnElMedio/image13.jpg';
import image14 from '../imagenes/imagenesTrabajamosEnElMedio/image14.jpg';
import image15 from '../imagenes/imagenesTrabajamosEnElMedio/image15.jpg';
import image16 from '../imagenes/imagenesTrabajamosEnElMedio/image16.jpg';
import image18 from '../imagenes/imagenesTrabajamosEnElMedio/image18.jpg';
import image19 from '../imagenes/imagenesTrabajamosEnElMedio/image19.jpg';
import image20 from '../imagenes/imagenesTrabajamosEnElMedio/image20.jpg';
import image21 from '../imagenes/imagenesTrabajamosEnElMedio/image21.jpg';
import image22 from '../imagenes/imagenesTrabajamosEnElMedio/image22.jpg';
import image23 from '../imagenes/imagenesTrabajamosEnElMedio/image23.jpg';
import image24 from '../imagenes/imagenesTrabajamosEnElMedio/image24.jpg';
import ContactSection from '../componentesHome/contacto';

const images = [
  image11, image12, image13, image14, image15,
  image16,  image18, image19, image20,
  image21, image22, image23, image24,
]; // Reemplazar con las rutas reales de las imágenes

const Techos = () => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [selectedImage, setSelectedImage] = useState(null);
  
    const handleImageClick = (image) => {
      setSelectedImage(image);
      onOpen();
    };
  
    const bgColor = useColorModeValue("gray.50", "gray.900");
    
    return (
        <div id='trabajamos-en-el-medio-section'>
          <h2 style={{margin:'190px'}}>Techos</h2>
      <Box bg={bgColor} p="6">
        <SimpleGrid columns={3} spacing="6">
          {images.map((image, index) => (
            <Box
              key={index}
              h="500px"
              w="100%"
              bg="gray.100"
              rounded="md"
              overflow="hidden"
              position="relative"
              _hover={{ transform: 'scale(1.05)', zIndex: '1' }}
              transition="all 0.3s ease-in-out"
              onClick={() => handleImageClick(image)}
            >
              <Image src={image} alt={`Imagen ${index + 1}`} objectFit="cover" w="100%" h="100%" />
            </Box>
          ))}
        </SimpleGrid>
        <Modal isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent
          width="100%"
          maxW="800px"  
  display="flex"
  flexDirection="column"
  alignItems="center"
  justifyContent="center"
 
 
  borderRadius="md">
 <ModalCloseButton position="absolute" right="2" top="2" />
  {selectedImage && (
    <Image
      src={selectedImage}
      alt="Imagen seleccionada"
      width="100%"
      maxW="800px"  
      // Establece un ancho máximo para la imagen
      h="auto"      // La altura se ajustará automáticamente para mantener las proporciones
          // Centra la imagen horizontalmente
          // Agrega margen en la parte superior e inferior de la imagen
    />
  )}
</ModalContent>
        </Modal>
      </Box>
      <ContactSection/>
    </div>
  );
};

export default Techos;
