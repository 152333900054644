import React, { useState } from 'react';
import { Box, SimpleGrid, Image, Modal, ModalOverlay, ModalContent, ModalCloseButton, useDisclosure, useColorModeValue } from '@chakra-ui/react';
import image1 from '../imagenes/imagenesTrabajamosEnElMedio/image1.jpg';
import image2 from '../imagenes/imagenesTrabajamosEnElMedio/image2.jpg';
import image3 from '../imagenes/imagenesTrabajamosEnElMedio/image3.jpg';
import image4 from '../imagenes/imagenesTrabajamosEnElMedio/image4.jpg';
import image5 from '../imagenes/imagenesTrabajamosEnElMedio/image5.jpg';
import image6 from '../imagenes/imagenesTrabajamosEnElMedio/image6.jpg';
import image7 from '../imagenes/imagenesTrabajamosEnElMedio/image7.jpg';
import image8 from '../imagenes/imagenesTrabajamosEnElMedio/image8.jpg';
import image9 from '../imagenes/imagenesTrabajamosEnElMedio/image9.jpg';
import image10 from '../imagenes/imagenesTrabajamosEnElMedio/image10.jpg';
import ContactSection from '../componentesHome/contacto';

const images = [image1, image2,image3,image4,image5,image6,image7,image8,image9,image10]; // Reemplazar con las rutas reales de las imágenes

const Camionetas = () => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [selectedImage, setSelectedImage] = useState(null);
  
    const handleImageClick = (image) => {
      setSelectedImage(image);
      onOpen();
    };
  
    const bgColor = useColorModeValue("gray.50", "gray.900");
    
    return (
        <div id='trabajamos-en-el-medio-section'>
          <h2 style={{margin:'190px'}}>Camionetas</h2>
      <Box bg={bgColor} p="6">
        <SimpleGrid columns={3} spacing="6">
          {images.map((image, index) => (
            <Box
              key={index}
              h="500px"
              w="100%"
              bg="gray.100"
              rounded="md"
              overflow="hidden"
              position="relative"
              _hover={{ transform: 'scale(1.05)', zIndex: '1' }}
              transition="all 0.3s ease-in-out"
              onClick={() => handleImageClick(image)}
            >
              <Image src={image} alt={`Imagen ${index + 1}`} objectFit="cover" w="100%" h="100%" />
            </Box>
          ))}
        </SimpleGrid>
        <Modal isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent
          width="100%"
          maxW="800px"  
  display="flex"
  flexDirection="column"
  alignItems="center"
  justifyContent="center"
 
 
  borderRadius="md">
 <ModalCloseButton position="absolute" right="2" top="2" />
  {selectedImage && (
    <Image
      src={selectedImage}
      alt="Imagen seleccionada"
      width="100%"
      maxW="800px"  
      // Establece un ancho máximo para la imagen
      h="auto"      // La altura se ajustará automáticamente para mantener las proporciones
          // Centra la imagen horizontalmente
          // Agrega margen en la parte superior e inferior de la imagen
    />
  )}
</ModalContent>
        </Modal>
      </Box>
      <ContactSection/>
    </div>
  );
};

export default Camionetas;
