import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import Home from './componentes/home';
import AboutUs from './componentesHome/aboutUs';
import Contacto from './componentesHome/contacto';
import Navbar from './componentesHome/navBar';
import Footer from './componentes/footer';
import Techos from './componentes/Techos';
import reportWebVitals from './reportWebVitals';
import { ChakraProvider } from '@chakra-ui/react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from 'react-router-dom';
import Camionetas from './componentes/Camionetas';
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <ChakraProvider>
      <Router>
        <Navbar />
        <Routes>
        <Route path="/1" element={<Camionetas />} />
        <Route path="/2" element={<Techos/>} />
          <Route path="/quienes-somos" element={<AboutUs />} />
          <Route path="/contacto" element={<Contacto />} />
         
          <Route path="/" element={<Home />} />
        </Routes>
        <Footer />
      </Router>
    </ChakraProvider>
  </React.StrictMode>
);

reportWebVitals();