import React, { useState, useEffect } from 'react';
import { FaFacebook, FaTwitter, FaInstagram } from 'react-icons/fa';
import { Link, useNavigate } from 'react-router-dom';
import { scroller } from 'react-scroll';
import '../css/navbar.css';
import MobileMenu from './movibleMenu';
import '../css/mobileMenu.css';
import imagen from '../imagenes/logoPurTecnica.png';

const Navbar = () => {
  const navigate = useNavigate();
  const [isNavbarTransparent, setIsNavbarTransparent] = useState(true);
  const [isMenuActive, setIsMenuActive] = useState(false);

  const handleScroll = () => {
    if (window.scrollY > 20) {
      setIsNavbarTransparent(false);
    } else {
      setIsNavbarTransparent(true);
    }
  };

  const handleMenuToggle = () => {
    setIsMenuActive(!isMenuActive);
  };

  const closeMenu = () => {
    setIsMenuActive(false);
  };

  const navigateAndScroll = (route, target) => {
    navigate(route);
    setTimeout(() => {
      scroller.scrollTo(target, {
        duration: 1000,
        smooth: true,
      });
    }, 100);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <nav className={`navbar ${isNavbarTransparent ? 'transparent' : 'solid'}`}>
      <Link to="/"> <img width={200} src={imagen} alt="" /></Link>
      <div className="navbar-links">
        <a className="scroll-link" onClick={() => navigateAndScroll('/', 'quienes-somos')}>Quienes Somos</a>
        <Link to="/contacto">Contacto</Link>
        <a className="scroll-link" onClick={() => navigateAndScroll('/', 'trabajamos-en-el-medio-section')}>Trabajamos en el medio</a>
      </div>
      <div className="navbar-social">
        <a href="https://www.facebook.com/purtecnica?mibextid=2JQ9oc" target="_blank" rel="noopener noreferrer">
          <FaFacebook />
        </a>
        <a href="https://www.instagram.com/purtecnica.aislaciones/" target="_blank" rel="noopener noreferrer">
          <FaInstagram />
        </a>
      </div>
      <div className="navbar-menu-icon" onClick={handleMenuToggle}>
        &#9776;
      </div>
      <MobileMenu isActive={isMenuActive} closeMenu={closeMenu} />
    </nav>
  );
};

export default Navbar;
