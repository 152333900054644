import React from 'react';
import { FaFacebook, FaTwitter, FaInstagram } from 'react-icons/fa';
import '../css/Footer.css';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-social">
        <a href="https://www.facebook.com/purtecnica?mibextid=2JQ9oc" target="_blank" rel="noopener noreferrer">
          <FaFacebook />
        </a>
        <a href="https://www.instagram.com/purtecnica.aislaciones/" target="_blank" rel="noopener noreferrer">
          <FaInstagram />
        </a>
      </div>
      <div className="footer-info">
        <p><strong>Dirección:</strong> Av. Cabildo 359</p>
        <p>Avellaneda, Provincia de Buenos Aires</p>
        <p><strong>E-mail:</strong> purtecnicaaislaciones@gmail.com</p>
        <p><strong>Celular:</strong> +54 9 11 6544-9039</p>

      </div>
      <div className="footer-credits">
        <p>© 2023 Todos los derechos reservados</p>
      </div>
    </footer>
  );
};

export default Footer;
