import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Box, Image, Text, Modal, ModalOverlay, ModalContent, ModalCloseButton, useDisclosure, useColorModeValue } from '@chakra-ui/react';
import imagenCamionetas from '../imagenes/imagenesTrabajamosEnElMedio/image10.jpg';
import imagenTechos from '../imagenes/imagenesTrabajamosEnElMedio/image15.jpg';

const imagenes = [imagenCamionetas, imagenTechos];

const TrabajamosEnElMedio = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [imagenSeleccionada, setImagenSeleccionada] = useState(null);

  const manejarClicEnImagen = (imagen) => {
    setImagenSeleccionada(imagen);
    onOpen();

    // Scroll hasta la parte superior de la página
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const bgColor = useColorModeValue('gray.50', 'gray.900');

  return (
    <div id="seccion-trabajamos-en-el-medio">
      <h2 style={{ margin: '190px' }}>Trabajamos en el Medio</h2>
      <Box bg={bgColor} p="6" display={{ base: 'block', md: 'flex' }} justifyContent={{ base: 'flex-start', md: 'space-around' }}>
        {imagenes.map((imagen, index) => (
          <Link key={index} to={`/${index + 1}`}>
            <Box
              key={index}
              h="500px"
              w="500px"
              flex={{ base: '0 0 100%', md: '0 0 48%' }}
              bg="gray.100"
              rounded="md"
              overflow="hidden"
              position="relative"
              m={{ base: '1%', md: '2%' }}  // Margen externo
              p="2%"  // Margen interno adicional para separar las imágenes
              _hover={{ transform: 'scale(1.05)', zIndex: '1' }}
              transition="all 0.3s ease-in-out"
              onClick={() => manejarClicEnImagen(imagen)}
            >
              <Image src={imagen} alt={`Imagen ${index + 1}`} objectFit="cover" w="100%" h="100%" />
              <Text
                position="absolute"
                top="50%"
                left="50%"
                transform="translate(-50%, -50%)"
                fontSize="lg"
                fontWeight="bold"
                color="white"
                bg="rgba(0, 0, 0, 0.5)" // Fondo oscuro semitransparente para mejorar la legibilidad
                padding="0.5rem"
                borderRadius="md"
              >
                {index === 0 ? 'Camionetas' : 'Techos'}
              </Text>
            </Box>
          </Link>
        ))}
        <Modal isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent
            width="100%"
            maxW="800px"
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            borderRadius="md"
          >
            <ModalCloseButton position="absolute" right="2" top="2" />
            {imagenSeleccionada && (
              <Image
                src={imagenSeleccionada}
                alt="Imagen seleccionada"
                width="100%"
                maxW="800px"
                h="auto"
              />
            )}
          </ModalContent>
        </Modal>
      </Box>
    </div>
  );
};

export default TrabajamosEnElMedio;
